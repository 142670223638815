import {
  BusinessIcon,
  FactoryIcon,
  FulfilmentIcon,
  HouseIcon,
  LastmileIcon,
  PortIcon,
  WarehouseIcon
} from 'assets/addressTypeIcons';
import {
  AirSTIcon,
  FCL20FRIcon,
  FCL20OTIcon,
  FCL20RFGIcon,
  FCL20STIcon,
  FCL20TankIcon,
  FCL40FRIcon,
  FCL40HCIcon,
  FCL40OTIcon,
  FCL40RFGIcon,
  FCL40STIcon,
  FCL45HCIcon,
  FTL20RFGIcon,
  FTL20STIcon,
  FTL40FRIcon,
  FTL40HCIcon,
  FTL40OTIcon,
  FTL40RFGIcon,
  FTL40STIcon,
  FTL45HCIcon,
  FTLIcon,
  LCLIcon,
  PkgIcon,
  RailClosedWagon,
  RailContainerPlatform,
  RailFlatWagon,
  RailFreightWagon,
  RailHopperWagon,
  RailSideDumpWagon,
  RailTankWagon,
  ShipBreakBulk,
  ShipCargoBulk,
  ShipOtherBulk,
  ROROIcon
} from '../assets/svg/bookingIcons';
import isEmpty from './isEmpty';

export const GENDER_LIST = [
  { label: 'Female', value: '0' },
  { label: 'Male', value: '1' },
  { label: 'Others', value: '2' }
];

export const LOCALE_LIST = [
  { label: 'Arabic', value: 'ar' },
  { label: 'Bulgarian', value: 'bg' },
  { label: 'Catalan', value: 'ca' },
  { label: 'Chinese', value: 'zh-TW' },
  { label: 'Chinese', value: 'zh' },
  { label: 'Dutch', value: 'nl' },
  { label: 'English', value: 'en' },
  { label: 'Estonian', value: 'et' },
  { label: 'Farsi', value: 'fa' },
  { label: 'Finnish', value: 'fi' },
  { label: 'French', value: 'fr' },
  { label: 'German', value: 'de' },
  { label: 'Greek', value: 'el' },
  { label: 'Hebrew', value: 'he' },
  { label: 'Hindi', value: 'hi' },
  { label: 'Hungarian', value: 'hu' },
  { label: 'Indonesian', value: 'id' },
  { label: 'Italiano', value: 'it' },
  { label: 'Japanese', value: 'ja' },
  { label: 'Korean', value: 'ko' },
  { label: 'Latvian', value: 'lv' },
  { label: 'Lithuanian', value: 'lt' },
  { label: 'Malay', value: 'ms' },
  { label: 'Norwegian', value: 'no' },
  { label: 'Polish', value: 'pl' },
  { label: 'Portugese', value: 'pt' },
  { label: 'Romanian', value: 'ro' },
  { label: 'Russian', value: 'ru' },
  { label: 'Slovak', value: 'sk' },
  { label: 'Spanish', value: 'es' },
  { label: 'Swedish', value: 'sv' },
  { label: 'Turkish', value: 'tr' },
  { label: 'Ukranian', value: 'ua' },
  { label: 'Vietnamese', value: 'vi' }
];

export const CURRENCY_LIST = [
  { label: 'Australian dollar', value: 'AUD' },
  { label: 'Brazilian real', value: 'BRL' },
  { label: 'Chinese yuan', value: 'CNY' },
  { label: 'Colombian peso', value: 'COP' },
  { label: 'Emirati dirham', value: 'AED' },
  { label: 'Euro', value: 'EUR' },
  { label: 'Hong Kong dollar', value: 'HKD' },
  { label: 'Indian rupee', value: 'INR' },
  { label: 'Mexican peso', value: 'MXN' },
  { label: 'Pound sterling', value: 'GBP' },
  { label: 'Saudi Arabian riyal', value: 'SAR' },
  { label: 'Singapore dollar', value: 'SGD' },
  { label: 'South African rand', value: 'ZAR' },
  { label: 'Swiss franc', value: 'CHF' },
  { label: 'Turkish lira', value: 'TRY' },
  { label: 'United States dollar', value: 'USD' }
];

export const TIMEZONE_LIST = [
  { label: '(GMT-08:00) Pacific Time (US & CANADA)', value: 'PT' },
  { label: '(GMT-07:00) Mountain Time (US & CANADA)', value: 'MT' },
  { label: '(GMT-06:00) Central Time (US & CANADA)', value: 'CT' },
  { label: '(GMT-05:00) Eastern Time (US & CANADA)', value: 'ET' },
  { label: '(GMT-04:00) Atlantic Time (CANADA)', value: 'AT' },
  { label: '(GMT-03:00) Greenland Time', value: 'GT' },
  { label: '(GMT-02:00) Mid-Atlantic', value: 'MA' },
  { label: '(GMT-01:00) Azores', value: 'AZ' },
  { label: '(GMT+00:00) UTC', value: 'UTC' },
  { label: '(GMT+01:00) West Central Africa', value: 'WCA' },
  { label: '(GMT+02:00) Europe/Athens', value: 'EUA' }
];

export const SHIPPING_ADDRESS_TYPES = {
  PORT: {
    label: 'Port / Airport',
    value: 'PRT',
    icon: PortIcon,
    origin: true,
    destination: true
  },
  STATION: {
    label: 'Container Freight Station',
    value: 'CFS',
    icon: WarehouseIcon,
    origin: true,
    destination: true
  },
  // FACTORY: {
  //   label: 'Factory warehouse',
  //   value: 'FWH',
  //   icon: FactoryIcon,
  //   origin: true,
  //   destination: true
  // },
  BUSINESS: {
    label: 'Business address',
    value: 'BIZ',
    icon: BusinessIcon,
    origin: true,
    destination: true
  },
  RESIDENTIAL: {
    label: 'Residential address',
    value: 'REZ',
    icon: HouseIcon,
    origin: true,
    destination: true
  }
  // FULFILMENT: {
  //   label: 'Fulfillment center',
  //   value: 'CTR',
  //   icon: FulfilmentIcon,
  //   origin: false,
  //   destination: true
  // },
  // LASTMILE: {
  //   label: 'Last mile delivery',
  //   value: 'LMD',
  //   icon: LastmileIcon,
  //   origin: false,
  //   destination: true
  // }
};

export const SHIPPING_ADDRESS_TYPES_LIST = [
  {
    label: 'Port/Airport',
    value: 'PRT',
    icon: PortIcon,
    origin: true,
    destination: true
  },
  // {
  //   label: 'Forwarder warehouse',
  //   value: 'FWD',
  //   icon: WarehouseIcon,
  //   origin: true,
  //   destination: false
  // },
  // {
  //   label: 'Factory warehouse',
  //   value: 'FWH',
  //   icon: FactoryIcon,
  //   origin: true,
  //   destination: true
  // },
  {
    label: 'Business address',
    value: 'BIZ',
    icon: BusinessIcon,
    origin: true,
    destination: true
  }
  // {
  //   label: 'Residential address',
  //   value: 'REZ',
  //   icon: HouseIcon,
  //   origin: true,
  //   destination: true
  // },
  // {
  //   label: 'Fulfillment center',
  //   value: 'CTR',
  //   icon: FulfilmentIcon,
  //   origin: false,
  //   destination: true
  // },
  // {
  //   label: 'Last mile delivery',
  //   value: 'LMD',
  //   icon: LastmileIcon,
  //   origin: false,
  //   destination: true
  // }
];

export const LOCATIONS_BY_COUNTRY = {
  CA: [
    {
      city_id: '652536f67a8ba5b42f2bfefa',
      label: 'Montreal (CAMTR)',
      code: 'CAMTR',
      value: 'CA-0',
      category: SHIPPING_ADDRESS_TYPES.PORT
    },
    {
      city_id: '652538b37a8ba5b42f2bff05',
      label: 'Toronto (CATOR)',
      code: 'CATOR',
      value: 'CA-1',
      category: SHIPPING_ADDRESS_TYPES.PORT
    },
    {
      city_id: '652536f67a8ba5b42f2bfefa',
      label: 'Montreal (CAWNP)',
      code: 'CAWNP',
      value: 'CA-2',
      category: SHIPPING_ADDRESS_TYPES.PORT
    },
    {
      city_id: '652536f67a8ba5b42f2bfefa',
      label: 'M8V, Etobicoke',
      code: 'M8V',
      value: 'CA-3',
      category: SHIPPING_ADDRESS_TYPES.BUSINESS
    }
    // {
    //   label: 'J5L, Calagry',
    //   code: 'J5L',
    //   value: 'CA-4',
    //   category: SHIPPING_ADDRESS_TYPES.FACTORY
    // }
  ],
  CN: [
    {
      label: 'Huandogg (CNMTR)',
      code: 'CNMTR',
      value: 'CN-0',
      category: SHIPPING_ADDRESS_TYPES.PORT
    },
    {
      label: 'Schzaddong (CNTOR)',
      code: 'CNTOR',
      value: 'CN-1',
      category: SHIPPING_ADDRESS_TYPES.PORT
    },
    {
      label: 'Chongqing (CNCNQ)',
      code: 'CNCNQ',
      value: 'CN-2',
      category: SHIPPING_ADDRESS_TYPES.PORT
    },
    {
      label: 'Chegdu, South',
      code: '54321',
      value: 'CN-3',
      category: SHIPPING_ADDRESS_TYPES.BUSINESS
    }
    // {
    //   label: 'Sukn Jin, North',
    //   code: '34563',
    //   value: 'CN-4',
    //   category: SHIPPING_ADDRESS_TYPES.FACTORY
    // }
  ]
};

export const CONTACT_REASONS = [
  { label: 'General Inquiries', value: 'General' },
  { label: 'Career', value: 'Career' },
  { label: 'Press', value: 'Press' },
  { label: 'Partnership', value: 'Partnership' },
  { label: 'Investing', value: 'Investing' }
];

export const SERVICE_PROVIDER_TYPES = {
  CU: 'CUSTOMS',
  FW: 'FORWARDER',
  IN: 'INSURANCE'
};

export const TRANSPORT_MODES_LIST = [
  { label: 'SEA', value: 'SEA', icon: 'ship' },
  { label: 'AIR', value: 'AIR', icon: 'plane' }
  // { label: 'LAND', value: 'LAND', icon: 'truck-moving' },
  // { label: 'RAIL', value: 'RAIL', icon: 'train' }
];

export const TRANSPORT_MODES = {
  SEA: { label: 'SEA', value: 'SEA', icon: 'ship' },
  RAIL: { label: 'RAIL', value: 'RAIL', icon: 'train' },
  AIR: { label: 'AIR', value: 'AIR', icon: 'plane' },
  LAND: { label: 'LAND', value: 'LAND', icon: 'truck-moving' }
};

export const CARGO_CATEGORIES = {
  FCL: {
    label: 'FCL',
    title: 'Full Container Load',
    value: 'FCL',
    icon: '/assets/bookingIcons/icon-fcl-40H.svg',
    mode: TRANSPORT_MODES.SEA
  },
  LCL: {
    label: 'LCL',
    title: 'Less Container Load',
    value: 'LCL',
    icon: LCLIcon,
    mode: TRANSPORT_MODES.SEA
  },
  RORO: {
    label: 'RORO',
    title: 'Roll-On Roll-Off',
    value: 'RORO',
    icon: '/assets/bookingIcons/icon-roro.svg',
    mode: TRANSPORT_MODES.SEA
  },
  BULK: {
    label: 'BULK',
    title: 'Bulk & Break Bulk',
    value: 'BULK',
    icon: ShipBreakBulk,
    mode: TRANSPORT_MODES.SEA
  },
  FRL: {
    label: 'RAIL',
    title: 'Full Container Load',
    value: 'FRL',
    icon: RailContainerPlatform,
    mode: TRANSPORT_MODES.RAIL
  },
  FCLT: {
    label: 'FCL',
    title: 'Full Container Load',
    value: 'FCLT',
    icon: FTL40HCIcon,
    mode: TRANSPORT_MODES.LAND
  },
  LTL: {
    label: 'LTL',
    title: 'Less Truck Load',
    value: 'LTL',
    icon: LCLIcon,
    mode: TRANSPORT_MODES.LAND
  },
  FTL: {
    label: 'FTL',
    title: 'Full Truck Load',
    value: 'FTL',
    icon: FTLIcon,
    mode: TRANSPORT_MODES.LAND
  },
  PKGS: {
    label: 'PKGS',
    title: 'Packages',
    value: 'PKGS',
    icon: '/assets/bookingIcons/icon-pkg.svg',
    mode: TRANSPORT_MODES.AIR
  }
};

export const fetchCargoCategories = (transportMode) => {
  const ccValues = Object.values(CARGO_CATEGORIES);
  let categories = ccValues;

  if (transportMode) {
    categories = ccValues.filter((cc) => cc.mode.value === transportMode.value);
  }
  console.log('fetchCargoCategories', transportMode, ccValues, categories);
  return categories;
};

export const CARGO_TYPES = {
  LCL: {
    label: 'Consolidation',
    value: 100,
    mode: TRANSPORT_MODES.SEA,
    icon: LCLIcon,
    category: CARGO_CATEGORIES.LCL
  },
  FCL_20_ST: {
    label: `20' Standard`,
    value: '20FT',
    mode: TRANSPORT_MODES.SEA,
    icon: '/assets/bookingIcons/icon-fcl-20.svg',
    category: CARGO_CATEGORIES.FCL
  },
  FCL_40_ST: {
    label: `40' Standard`,
    value: '40FT',
    mode: TRANSPORT_MODES.SEA,
    icon: '/assets/bookingIcons/icon-fcl-40.svg',
    category: CARGO_CATEGORIES.FCL
  },
  FCL_40_HC: {
    label: `40' High Cube`,
    value: '40FT HC',
    mode: TRANSPORT_MODES.SEA,
    icon: '/assets/bookingIcons/icon-fcl-40.svg',
    category: CARGO_CATEGORIES.FCL
  },
  FCL_45_HC: {
    label: `45 High Cube`,
    value: '45FT HC',
    mode: TRANSPORT_MODES.SEA,
    icon: '/assets/bookingIcons/icon-fcl-45H.svg',
    category: CARGO_CATEGORIES.FCL
  },
  // FCL_20_ST: {
  //   label: `20' Standard`,
  //   value: 101,
  //   mode: TRANSPORT_MODES.SEA,
  //   icon: FCL20STIcon,
  //   category: CARGO_CATEGORIES.FCL
  // },
  // FCL_40_ST: {
  //   label: `40' Standard`,
  //   value: 102,
  //   mode: TRANSPORT_MODES.SEA,
  //   icon: FCL40STIcon,
  //   category: CARGO_CATEGORIES.FCL
  // },
  // FCL_40_HC: {
  //   label: `40' High Cube`,
  //   value: 103,
  //   mode: TRANSPORT_MODES.SEA,
  //   icon: FCL40HCIcon,
  //   category: CARGO_CATEGORIES.FCL
  // },
  // FCL_45_HC: {
  //   label: `45 High Cube`,
  //   value: 104,
  //   mode: TRANSPORT_MODES.SEA,
  //   icon: FCL45HCIcon,
  //   category: CARGO_CATEGORIES.FCL
  // },
  // FCL_20_OT: {
  //   label: `20' Open Top`,
  //   value: 105,
  //   mode: TRANSPORT_MODES.SEA,
  //   icon: FCL20OTIcon,
  //   category: CARGO_CATEGORIES.FCL
  // },
  // FCL_40_OT: {
  //   label: `40' Open Top`,
  //   value: 106,
  //   mode: TRANSPORT_MODES.SEA,
  //   icon: FCL40OTIcon,
  //   category: CARGO_CATEGORIES.FCL
  // },
  // FCL_20_FR: {
  //   label: `20' Flatrack`,
  //   value: 107,
  //   mode: TRANSPORT_MODES.SEA,
  //   icon: FCL20FRIcon,
  //   category: CARGO_CATEGORIES.FCL
  // },
  // FCL_40_FR: {
  //   label: `40' Flatrack`,
  //   value: 108,
  //   mode: TRANSPORT_MODES.SEA,
  //   icon: FCL40FRIcon,
  //   category: CARGO_CATEGORIES.FCL
  // },
  // FCL_20_RFG: {
  //   label: `20' Refrigerated`,
  //   value: 109,
  //   mode: TRANSPORT_MODES.SEA,
  //   icon: FCL20RFGIcon,
  //   category: CARGO_CATEGORIES.FCL
  // },
  // FCL_40_RFG: {
  //   label: `40' Refrigerated`,
  //   value: 110,
  //   mode: TRANSPORT_MODES.SEA,
  //   icon: FCL40RFGIcon,
  //   category: CARGO_CATEGORIES.FCL
  // },
  // FCL_20_TNK: {
  //   label: `20' Tank`,
  //   value: 111,
  //   mode: TRANSPORT_MODES.SEA,
  //   icon: FCL20TankIcon,
  //   category: CARGO_CATEGORIES.FCL
  // },
  SHIP_BREAK_BULK: {
    label: 'Break Bulk',
    value: 112,
    mode: TRANSPORT_MODES.SEA,
    icon: ShipBreakBulk,
    category: CARGO_CATEGORIES.BULK
  },
  SHIP_CARGO_BULK: {
    label: 'Cargo Bulk',
    value: 113,
    mode: TRANSPORT_MODES.SEA,
    icon: ShipCargoBulk,
    category: CARGO_CATEGORIES.BULK
  },
  SHIP_OTHER_BULK: {
    label: 'Other Bulk',
    value: 114,
    mode: TRANSPORT_MODES.SEA,
    icon: ShipOtherBulk,
    category: CARGO_CATEGORIES.BULK
  },

  // RORO shipping
  SHIP_AUTOMOBILE_RORO: {
    label: 'Automobile',
    value: 'automobile', // AUT Automobile
    mode: TRANSPORT_MODES.SEA,
    icon: '/assets/bookingIcons/icon-roro.svg',
    category: CARGO_CATEGORIES.RORO
  },
  SHIP_TRAILER_TRUCK_RORO: {
    label: 'Trailer / Truck',
    value: 'truck', //TTR Trailer / Truck
    mode: TRANSPORT_MODES.SEA,
    icon: '/assets/bookingIcons/icon-roro.svg',
    category: CARGO_CATEGORIES.RORO
  },
  SHIP_INDUSTRIAL_VEHICLE_RORO: {
    label: 'Industrial Vehicle',
    value: 'industrialVehicle', //IDV Industrial Vehicle
    mode: TRANSPORT_MODES.SEA,
    icon: '/assets/bookingIcons/icon-roro.svg',
    category: CARGO_CATEGORIES.RORO
  },
  SHIP_HIGH_HEAVY_MACHINERY_RORO: {
    label: 'High & Heavy Machinery',
    value: 'heavyMachinery', //HHM High & Heavy Machinery
    mode: TRANSPORT_MODES.SEA,
    icon: '/assets/bookingIcons/icon-roro.svg',
    category: CARGO_CATEGORIES.RORO
  },
  SHIP_MOTORCYCLE_ATV_RORO: {
    label: 'Motorcycle (crated or palletized) / ATV',
    value: 'motorcycle', //ATV Motorcycle / ATV
    mode: TRANSPORT_MODES.SEA,
    icon: '/assets/bookingIcons/icon-roro.svg',
    category: CARGO_CATEGORIES.RORO
  },
  SHIP_MOTORHOME_RV_RORO: {
    label: 'Motorhome / RV',
    value: 'motorhome', //MRV Motorhome / RV
    mode: TRANSPORT_MODES.SEA,
    icon: '/assets/bookingIcons/icon-roro.svg',
    category: CARGO_CATEGORIES.RORO
  },
  SHIP_VAN_BUS_RORO: {
    label: 'Van / Bus',
    value: 'van', // VAB Van / Bus
    mode: TRANSPORT_MODES.SEA,
    icon: '/assets/bookingIcons/icon-roro.svg',
    category: CARGO_CATEGORIES.RORO
  },
  SHIP_BOAT_JETSKI_RORO: {
    label: 'Boat / Jet Ski (loaded on trailer)',
    value: 'boat', //BJS Boat / Jet Ski
    mode: TRANSPORT_MODES.SEA,
    icon: '/assets/bookingIcons/icon-roro.svg',
    category: CARGO_CATEGORIES.RORO
  },
  SHIP_AIRCRAFT_HELICOPTER_RORO: {
    label: 'Aircraft / Helicopter',
    value: 'aircraft', //ACH Aircraft / Helicopter
    mode: TRANSPORT_MODES.SEA,
    icon: '/assets/bookingIcons/icon-roro.svg',
    category: CARGO_CATEGORIES.RORO
  },
  SHIP_OTHER_RORO: {
    label: 'Other',
    value: 'other', //OTH Other
    mode: TRANSPORT_MODES.SEA,
    icon: '/assets/bookingIcons/icon-roro.svg',
    category: CARGO_CATEGORIES.RORO
  },

  AIR_ST: {
    label: 'Air Standard',
    value: 'AIR ST',
    mode: TRANSPORT_MODES.AIR,
    icon: '/assets/bookingIcons/icon-air-standard.svg',
    category: CARGO_CATEGORIES.PKGS
  },

  FRL_CONTAINER_PLATFORM: {
    label: 'Container Platform',
    value: 300,
    mode: TRANSPORT_MODES.RAIL,
    icon: RailContainerPlatform,
    category: CARGO_CATEGORIES.FRL
  },
  FRL_CLOSED_WAGON: {
    label: 'Closed Wagon',
    value: 301,
    mode: TRANSPORT_MODES.RAIL,
    icon: RailClosedWagon,
    category: CARGO_CATEGORIES.FRL
  },
  FRL_FLAT_WAGON: {
    label: 'Flat Wagon',
    value: 302,
    mode: TRANSPORT_MODES.RAIL,
    icon: RailFlatWagon,
    category: CARGO_CATEGORIES.FRL
  },
  FRL_FREIGHT_WAGON: {
    label: 'Freight Wagon',
    value: 303,
    mode: TRANSPORT_MODES.RAIL,
    icon: RailFreightWagon,
    category: CARGO_CATEGORIES.FRL
  },
  FRL_HOPPER_WAGON: {
    label: 'Hopper Wagon',
    value: 304,
    mode: TRANSPORT_MODES.RAIL,
    icon: RailHopperWagon,
    category: CARGO_CATEGORIES.FRL
  },
  FRL_SIDE_DUMP_WAGON: {
    label: 'Side Dump Wagon',
    value: 305,
    mode: TRANSPORT_MODES.RAIL,
    icon: RailSideDumpWagon,
    category: CARGO_CATEGORIES.FRL
  },
  FRL_TANK_WAGON: {
    label: 'Tank Wagon',
    value: 306,
    mode: TRANSPORT_MODES.RAIL,
    icon: RailTankWagon,
    category: CARGO_CATEGORIES.FRL
  },

  FTL: {
    label: 'Truck load',
    value: 400,
    mode: TRANSPORT_MODES.LAND,
    icon: FTLIcon,
    category: CARGO_CATEGORIES.FTL
  },
  FTL_20_ST: {
    label: `20' Standard`,
    value: 401,
    mode: TRANSPORT_MODES.LAND,
    icon: FTL20STIcon,
    category: CARGO_CATEGORIES.FCL
  },
  FTL_40_ST: {
    label: `40' Standard`,
    value: 402,
    mode: TRANSPORT_MODES.LAND,
    icon: FTL40STIcon,
    category: CARGO_CATEGORIES.FCL
  },
  FTL_40_HC: {
    label: `40' High Cube`,
    value: 403,
    mode: TRANSPORT_MODES.LAND,
    icon: FTL40HCIcon,
    category: CARGO_CATEGORIES.FCL
  },
  FTL_45_HC: {
    label: `45 High Cube`,
    value: 404,
    mode: TRANSPORT_MODES.LAND,
    icon: FTL45HCIcon,
    category: CARGO_CATEGORIES.FCL
  },
  // FTL_20_OT: {
  //   label: `20' Open Top`,
  //   value: 405,
  //   mode: TRANSPORT_MODES.TRUCK,
  //   icon: FCL20OTIcon,
  //   category: CONTAINER_CATEGORIES.FTL
  // },
  FTL_40_OT: {
    label: `40' Open Top`,
    value: 406,
    mode: TRANSPORT_MODES.LAND,
    icon: FTL40OTIcon,
    category: CARGO_CATEGORIES.FCL
  },
  // FTL_20_FR: {
  //   label: `20' Flatrack`,
  //   value: 407,
  //   mode: TRANSPORT_MODES.TRUCK,
  //   icon: FTL20STIcon,
  //   category: CONTAINER_CATEGORIES.FTL
  // },
  FTL_40_FR: {
    label: `40' Flatrack`,
    value: 408,
    mode: TRANSPORT_MODES.LAND,
    icon: FTL40FRIcon,
    category: CARGO_CATEGORIES.FCL
  },
  FTL_20_RFG: {
    label: `20' Refrigerated`,
    value: 409,
    mode: TRANSPORT_MODES.LAND,
    icon: FTL20RFGIcon,
    category: CARGO_CATEGORIES.FCL
  },
  FTL_40_RFG: {
    label: `40' Refrigerated`,
    value: 410,
    mode: TRANSPORT_MODES.LAND,
    icon: FTL40RFGIcon,
    category: CARGO_CATEGORIES.FCL
  },
  LTL: {
    label: 'Less Truck load',
    value: 411,
    mode: TRANSPORT_MODES.LAND,
    icon: LCLIcon,
    category: CARGO_CATEGORIES.LTL
  }
};

export const fetchContainerTypes = (transportMode, containerCategory) => {
  const options = Object.values(CARGO_TYPES);
  let filteredOpts = options || [];
  if (transportMode.value === TRANSPORT_MODES.RAIL.value) {
    filteredOpts = options.filter(
      (c) =>
        (c.mode.value === transportMode.value ||
          c.mode.value === TRANSPORT_MODES.SEA.value) &&
        (c.category.value === containerCategory.value ||
          c.category.value === CARGO_CATEGORIES.FCL.value)
    );
  } else if (
    containerCategory &&
    transportMode &&
    transportMode.value !== 'all'
  ) {
    filteredOpts = options.filter(
      (c) =>
        c.mode.value === transportMode.value &&
        c.category.value === containerCategory.value
    );
  }

  return filteredOpts;
};

export const SHIPMENT_STATUS = {
  CARGO_HOLD: { value: 1, label: 'Cargo hold' },
  CARGO_READY: { value: 1, label: 'Cargo ready' },
  CARGO_PICKED_UP: { value: 2, label: 'Cargo picked up' },
  DELIVERED_TO_FORWARDER: { value: 3, label: 'Delivered to forwarder' },
  DEPART_FOR_SHIPPING: { value: 4, label: 'Rail departed Origin' },
  SHIPPING_IN_PROGRESS: { value: 5, label: 'Shipping in progress' },
  ARRIVED_AT_DESTINATION: { value: 6, label: 'Arrived at destination port' },
  CUSTOM_CLEARANCE: { value: 7, label: 'Custom clearance' },
  OUT_FOR_DELIVERY: { value: 8, label: 'Out for delivery' },
  DELIVERED: { value: 9, label: 'Delivered at Destination' },
  COMPLETED: { value: 10, label: 'Completed' }
};

export const CONTAINER_STATUS = {
  EMPTY_TO_SHIPPER: { value: 1, label: 'Empty to Shipper' },
  GATE_IN_FULL: { value: 2, label: 'Gate In From' },
  RAIL_DEPART_ORIGIN: { value: 3, label: 'Rail departed Origin' },
  RAIL_ARRIVE_RAMP: { value: 4, label: 'Arrived at rail ramp' },
  POSITIONED_OUT_ORIGIN: { value: 5, label: 'Positioned Out From' },
  POSITIONED_IN_ORIGIN: { value: 6, label: 'Positioned In From' },
  LOADED: { value: 7, label: 'Loaded' },
  TRANSSHIPMENT_DISCHARGED: { value: 8, label: 'Transshipment Discharged' },
  TRANSSHIPMENT_LOADED: { value: 9, label: 'Transshipment Loaded' },
  DISCHARGED: { value: 10, label: 'Discharged' },
  POSITIONED_OUT_DESTINATION: { value: 11, label: 'Postioned Out To' },
  POSITIONED_IN_DESTINATION: { value: 12, label: 'Positioned In To' },
  GATE_OUT_FULL: { value: 13, label: 'Gate Out To' },
  EMPTY_AT_YARD: { value: 14, label: 'Gate In To' }
};

export const PAYMENT_STATUS = {
  PAID: { value: 1, label: 'Paid', color: 'success' },
  REFUNDED: { value: 2, label: 'Refunded', color: 'info' },
  PENDING: { value: 3, label: 'Pending', color: 'warning' },
  REJECTED: { value: 4, label: 'Rejected', color: 'danger' }
};

export const PAYMENT_METHOD = {
  CARD: { value: 1, label: 'Card' },
  PAYPAL: { value: 2, label: 'Paypal' },
  BANK: { value: 3, label: 'Bank' },
  CASH: { value: 4, label: 'Cash' }
};

export const CONTAINER_STATUS_LENGTH = 14;

export const SIDEBAR_DRAWER_WIDTH = 240;

export const MIN_SIDEBAR_DRAWER_WIDTH = 74;

export const RIGHT_DRAWER_WIDTH = '50%';

export const MIN_RIGHT_DRAWER_WIDTH = 550;

export const DIMENSION_UNITS = ['CM', 'IN'];

export const WEIGHT_UNITS = ['KG', 'LB'];

export const VOLUME_UNITS_LIST = ['CBM', 'CFT'];

export const VOLUME_UNITS = {
  CFT: { value: 'CFT', label: 'CFT', description: 'Cubic Feet' },
  CBM: { value: 'CBM', label: 'CBM', description: 'Cubic Meter' }
};

export const calculationMethodTypes = {
  dimension: {
    label: 'Dimensions',
    value: 'dimension'
  },
  volume: {
    label: 'Volume',
    value: 'volume'
  },
  noDimensions: {
    label: 'Weight & Packaging',
    value: 'noDimensions'
  }
};

export const VOL_DIVISOR_CM_TO_CBM = 1000000;
export const VOL_DIVISOR_IN_TO_CFT = 1728;

export const DEFAULT_LCL_WEIGHT = { unit: WEIGHT_UNITS[1], weight: 0 };

export const DEFAULT_LCL_VOLUME = { unit: VOLUME_UNITS_LIST[0], volume: 0 };

export const LCL_MAX_DIMENSION_CM = 400;

export const LCL_MAX_DIMENSION_IN = 158;

export const LCL_MAX_VOLUME_CBM = 43;

export const LCL_MAX_VOLUME_CFT = 1500;

export const LCL_MAX_WEIGHT_KG = 34000;

export const LCL_MAX_WEIGHT_LB = 75000;

export const PALLETS_SIZES = [
  {
    value: 'PLT1',
    label: '48" x 40"',
    dimensions: { length: 48, width: 40, height: 0, unit: DIMENSION_UNITS[1] },
    specified: true
  },
  {
    value: 'PLT2',
    label: '120 x 80CM (EUR1)',
    dimensions: { length: 120, width: 80, height: 0, unit: DIMENSION_UNITS[0] },
    specified: true
  },
  {
    value: 'PLT3',
    label: '120 x 100CM (EUR2)',
    dimensions: {
      length: 120,
      width: 100,
      height: 0,
      unit: DIMENSION_UNITS[0]
    },
    specified: true
  },
  {
    value: 'PLT4',
    label: 'Pallets (non specified size)',
    dimensions: { length: 0, width: 0, height: 0, unit: DIMENSION_UNITS[0] },
    specified: false
  }
];

export const RORO_CARGO_TYPES_CLASSES = {
  // Automobile
  automobile: [
    {
      description: '< 1,500 kg (Small cars, compact cars, economy vehicles)',
      class: 'light',
      label: 'Lightweight',
      range: {
        min: 0,
        max: 1500
      }
    },
    {
      description: '1,500 - 2,500 kg (Sedans, small SUVs, crossovers)',
      class: 'medium',
      label: 'Midweight',
      range: {
        min: 1501,
        max: 2500
      }
    },
    {
      description:
        '2,500 - 4,000 kg (Luxury cars, large SUVs, long-wheel based)',
      class: 'heavy',
      label: 'Heavyweight',
      range: {
        min: 2501,
        max: 4000
      }
    },
    {
      description: '> 4,000 kg (GMC Hummer, Ford trucks)',
      class: 'super',
      label: 'Super Heavyweight',
      range: {
        min: 4001
      }
    }
  ],
  // Trailer / Truck
  truck: [
    {
      description: '< 3,500 kg (Small trailers, utility trucks)',
      class: 'light',
      label: 'Light Duty',
      range: {
        min: 0,
        max: 3500
      }
    },
    {
      description: '3,500 - 7,500 kg (Box trucks, larger trailers)',
      class: 'medium',
      label: 'Medium Duty',
      range: {
        min: 3501,
        max: 7500
      }
    },
    {
      description: '7,500 - 12,000 kg (Heavy-duty trucks, large trailers)',
      class: 'heavy',
      label: 'Heavy Duty ',
      range: {
        min: 7501,
        max: 12000
      }
    },
    {
      description: '> 12,000 kg (Semi-trailers, large commercial trucks)',
      class: 'super',
      label: 'Extra Heavy Duty',
      range: {
        min: 12001
      }
    }
  ],
  // Industrial Vehicle
  industrialVehicle: [
    {
      description: '< 4,500 kg (Forklifts, small loaders)',
      class: 'light',
      label: 'Light Vehicle',
      range: {
        min: 0,
        max: 4500
      }
    },
    {
      description: '4,500 - 10,000 kg (Excavators, backhoes)',
      class: 'medium',
      label: 'Medium Vehicle',
      range: {
        min: 4501,
        max: 10000
      }
    },
    {
      description: '10,000 - 30,000 kg (Large loaders, cranes)',
      class: 'heavy',
      label: 'Heavy Vehicle',
      range: {
        min: 10001,
        max: 30000
      }
    },
    {
      description: '> 30,000 kg (Bulldozers, industrial cranes)',
      class: 'super',
      label: 'Super Heavy Vehicle',
      range: {
        min: 30001
      }
    }
  ],
  // High & Heavy Machinery
  heavyMachinery: [
    {
      description:
        '< 20,000 kg (Agricultural machinery, small construction machinery)',
      class: 'light',
      label: 'Light Machinery',
      range: {
        min: 0,
        max: 20000
      }
    },
    {
      description: '20,000 - 40,000 kg (Mid-size earthmovers, tractors)',
      class: 'medium',
      label: 'Medium Machinery',
      range: {
        min: 20001,
        max: 40000
      }
    },
    {
      description:
        '40,000 - 60,000 kg (Large construction and mining equipment)',
      class: 'heavy',
      label: 'Heavy Machinery',
      range: {
        min: 40001,
        max: 60000
      }
    },
    {
      description: '> 60,000 kg (Massive industrial machinery, large cranes)',
      class: 'super',
      label: 'Super Heavy Machinery',
      range: {
        min: 60000
      }
    }
  ],
  // Motorcycle / ATV
  motorcycle: [
    {
      description: '< 250 kg (Scooters, mopeds, small ATVs)',
      class: 'light',
      label: 'Light Motorcycle',
      range: {
        min: 0,
        max: 250
      }
    },
    {
      description: '250 - 400 kg (Motorcycles, mid-range ATVs)',
      class: 'medium',
      label: 'Standard Motorcycle',
      range: {
        min: 251,
        max: 400
      }
    },
    {
      description: '> 400 kg (Touring bikes, large ATVs, trikes)',
      class: 'heavy',
      label: 'Heavy Motorcycle',
      range: {
        min: 401
      }
    }
  ],
  // Motorhome / RV
  motorhome: [
    {
      description: '< 4,500 kg (Class B: Small campervans)',
      class: 'light',
      label: 'Light - Class B',
      range: {
        min: 0,
        max: 4500
      }
    },
    {
      description: '4,500 - 7,500 kg (Class C: Mid-size RVs, large campervans)',
      class: 'medium',
      label: 'Medium - Class C',
      range: {
        min: 4501,
        max: 7500
      }
    },
    {
      description: '7,500 - 15,000 kg (Class A: Large motorhomes, luxury RVs)',
      class: 'heavy',
      label: 'Heavy - Class A',
      range: {
        min: 7501,
        max: 15000
      }
    },
    {
      description: '> 15,000 kg (Super Class A: Heavy, luxury motorhomes)',
      class: 'super',
      label: 'Super - Class A',
      range: {
        min: 15001
      }
    }
  ],
  // Van / Bus
  van: [
    {
      description: '< 8,000 kg (Passenger vans, small buses)',
      class: 'light',
      label: 'Light Van / Minibus',
      range: {
        min: 0,
        max: 8000
      }
    },
    {
      description: '8,001 - 15,000 kg (Cargo vans, school buses)',
      class: 'medium',
      label: 'Medium Bus',
      range: {
        min: 8001,
        max: 15000
      }
    },
    {
      description:
        '15,001 - 25,000 kg (Coach buses, large commercial vehicles)',
      class: 'heavy',
      label: 'Heavy Bus',
      range: {
        min: 15001,
        max: 25000
      }
    },
    {
      description: '> 25,000 kg (Luxury buses, articulated buses)',
      class: 'super',
      label: 'Super Heavy Bus',
      range: {
        min: 25001
      }
    }
  ],
  //  Boat / Jet Ski
  boat: [
    {
      description: '< 2,000 kg (Small boats, jet skis)',
      class: 'light',
      label: 'Light Boats',
      range: {
        min: 0,
        max: 2000
      }
    },
    {
      description: '2,000 - 5,000 kg (Speedboats, small yachts)',
      class: 'medium',
      label: 'Midweight Boats',
      range: {
        min: 2001,
        max: 5000
      }
    },
    {
      description: '5,000 - 10,000 kg (Mid-size yachts, fishing vessels)',
      class: 'MD',
      label: 'Heavy Boats',
      range: {
        min: 5001,
        max: 10000
      }
    },
    {
      description: '> 10,000 kg (Large yachts, commercial boats)',
      class: 'super',
      label: 'Super Heavy Boats',
      range: {
        min: 10001
      }
    }
  ],
  // Aircraft / Helicopter
  aircraft: [
    {
      description: '< 3,500 kg (Small helicopters, light planes)',
      class: 'light',
      label: 'Light Aircraft',
      range: {
        min: 0,
        max: 3500
      }
    },
    {
      description:
        '3,500 - 10,000 kg (Mid-size helicopters, small commercial planes)',
      class: 'medium',
      label: 'Medium Aircraft',
      range: {
        min: 3501,
        max: 10000
      }
    },
    {
      description: '10,000 - 40,000 kg (Commercial planes, large helicopters)',
      class: 'heavy',
      label: 'Heavy Aircraft',
      range: {
        min: 10001,
        max: 40000
      }
    },
    {
      description: '> 40,000 kg (Military aircraft, large commercial jets)',
      class: 'super',
      label: 'Super Heavy Aircraft',
      range: {
        min: 40001
      }
    }
  ],
  other: [
    {
      description: '< 3,500 kg (Small cargo)',
      class: 'light',
      label: 'Light Cargo',
      range: {
        min: 0,
        max: 3500
      }
    },
    {
      description: '3,500 - 10,000 kg (Mid-size cargo)',
      class: 'medium',
      label: 'Medium Cargo',
      range: {
        min: 3501,
        max: 10000
      }
    },
    {
      description: '10,000 - 40,000 kg (Heavy cargo)',
      class: 'heavy',
      label: 'Heavy Cargo',
      range: {
        min: 10001,
        max: 40000
      }
    },
    {
      description: '> 40,000 kg (Super heavy cargo)',
      class: 'super',
      label: 'Super Heavy Cargo',
      range: {
        min: 40001
      }
    }
  ]
};

export const AIR_CARGO_CLASSES = {
  GENERAL_CARGO: {
    label: 'General Cargo',
    value: 'GENC'
  },
  PERISHABLES: {
    label: 'Perishables',
    value: 'PERI'
  },
  DANGEROUS_GOODS: {
    label: 'Dangerous Goods',
    value: 'DANG'
  },
  PHARMACEUTICALS: {
    label: 'Pharmaceuticals and Healthcare Products',
    value: 'PHRM'
  },
  VALUABLE_CARGO: {
    label: 'Valuable Cargo',
    value: 'VAL'
  },
  LIVESTOCK_ANIMALS: {
    label: 'Livestock and Animals',
    value: 'ANIM'
  },
  HUMAN_REMAINS: {
    label: 'Human Remains',
    value: 'HUMA'
  },
  OVERSIZED_CARGO: {
    label: 'Oversized Cargo',
    value: 'OVSZ'
  },
  TEMPERATURE_SENSITIVE: {
    label: 'Temperature-Sensitive Goods',
    value: 'TEMP'
  },
  DIPLOMATIC_MAIL: {
    label: 'Diplomatic Mail',
    value: 'DIPM'
  },
  EXPRESS_CARGO: {
    label: 'Express Cargo',
    value: 'EXPC'
  },
  PERSONAL_HOUSEHOLD_EFFECTS: {
    label: 'Personal and Household Effects',
    value: 'PERS'
  },
  COLD_CHAIN: {
    label: 'Cold Chain Cargo',
    value: 'COLD'
  },
  BULKY_CARGO: {
    label: 'Bulky Cargo',
    value: 'BLKY'
  },
  CONSOLIDATED_CARGO: {
    label: 'Consolidated Cargo',
    value: 'CNSL'
  },
  HUMANITARIAN_AID: {
    label: 'Humanitarian Aid',
    value: 'HAID'
  },
  DOCUMENTS: {
    label: 'Documents and Papers',
    value: 'DOCU'
  },
  ELECTRONICS_HIGH_VALUE: {
    label: 'Electronics and High-Value Items',
    value: 'ELEC'
  },
  TIME_CRITICAL: {
    label: 'Time-Critical Cargo',
    value: 'TCRI'
  },
  AVIATION_PARTS: {
    label: 'Aviation Parts and Equipment',
    value: 'AVIA'
  },
  SPORTING_GOODS: {
    label: 'Sporting Goods',
    value: 'SPRT'
  },
  AGRICULTURAL: {
    label: 'Agricultural Products',
    value: 'AGRO'
  },
  ART_ANTIQUES: {
    label: 'Art and Antiques',
    value: 'ARTS'
  },
  RESEARCH_EQUIPMENT: {
    label: 'Research Equipment',
    value: 'REQP'
  },
  FASHION_APPAREL: {
    label: 'Fashion and Apparel',
    value: 'FASH'
  },
  AUTOMOTIVE_PARTS: {
    label: 'Automotive Parts',
    value: 'CARP'
  },
  BULK_LIQUIDS: {
    label: 'Bulk Liquids',
    value: 'BLIQ'
  },
  LIVE_FISH: {
    label: 'Live Fish',
    value: 'FISH'
  },
  JEWELRY: {
    label: 'Jewelry and Precious Metals',
    value: 'JEWL'
  },
  MEDIA_PUBLICATIONS: {
    label: 'Media and Publications',
    value: 'MDIA'
  },
  FOOD_BEVERAGES: {
    label: 'Food and Beverages',
    value: 'FOOD'
  },
  SPECIAL_HANDLING: {
    label: 'Special Handling Required',
    value: 'SPEC'
  }
};

export const SEA_CARGO_CLASSES = {
  AGRICULTURAL: {
    label: 'Agricultural Products',
    value: 'AGRO'
  },
  AUTOMOBILES: {
    label: 'Automobiles and Vehicles',
    value: 'AUTO'
  },
  INDUSTRIAL_MACHINERY: {
    label: 'Industrial Machinery and Equipment',
    value: 'MACH'
  },
  BUILDING_MATERIALS: {
    label: 'Building Materials',
    value: 'BLDG'
  },
  CONSUMER_GOODS: {
    label: 'Consumer Goods',
    value: 'CONG'
  },
  CHEMICALS_HAZARDOUS: {
    label: 'Chemicals and Hazardous Materials',
    value: 'HAZD'
  },
  FOOD_BEVERAGES: {
    label: 'Food and Beverages',
    value: 'FOOD'
  },
  PHARMACEUTICALS: {
    label: 'Pharmaceuticals and Healthcare Products',
    value: 'PHRM'
  },
  TEXTILES_APPAREL: {
    label: 'Textiles and Apparel',
    value: 'TXTL'
  },
  FURNITURE_HOME_GOODS: {
    label: 'Furniture and Home Goods',
    value: 'FURN'
  },
  RAW_MATERIALS: {
    label: 'Raw Materials',
    value: 'RAWM'
  },
  LIVESTOCK_ANIMALS: {
    label: 'Livestock and Animals',
    value: 'ANIM'
  },
  ELECTRONICS_HIGH_VALUE: {
    label: 'Electronics and High-Value Items',
    value: 'ELEC'
  },
  PAPER_WOOD_PRODUCTS: {
    label: 'Paper and Wood Products',
    value: 'PAWP'
  },
  PERSONAL_HOUSEHOLD_EFFECTS: {
    label: 'Personal and Household Effects',
    value: 'PERS'
  },
  METAL_STEEL_PRODUCTS: {
    label: 'Metal and Steel Products',
    value: 'METL'
  },
  MARINE_BOATING_EQUIPMENT: {
    label: 'Marine and Boating Equipment',
    value: 'BOAT'
  },
  LUXURY_FRAGILE_GOODS: {
    label: 'Luxury and Fragile Goods',
    value: 'LUXE'
  },
  RECYCLABLE_WASTE_MATERIALS: {
    label: 'Recyclable and Waste Materials',
    value: 'RECY'
  },
  TEMPERATURE_SENSITIVE: {
    label: 'Temperature-Sensitive Goods',
    value: 'TEMP'
  },
  ENERGY_FUEL: {
    label: 'Energy and Fuel',
    value: 'FUEL'
  },
  PROJECT_CARGO: {
    label: 'Project Cargo / Heavy Lift Cargo',
    value: 'HCRG'
  },
  PERISHABLES: {
    label: 'Perishables',
    value: 'PERI'
  },
  WASTE_SCRAP: {
    label: 'Waste and Scrap Materials',
    value: 'SCRP'
  },
  BULK_LIQUIDS: {
    label: 'Bulk Liquids',
    value: 'BLIQ'
  }
};

export const AIR_CARGO_PACKAGE_TYPES = {
  ULD_PALLET: {
    label: 'Unit Load Device - Pallet',
    value: 'ULD_PAL',
    dimensions: { unit: 'CM', length: 244, width: 122, height: 160 },
    volume: { unit: 'CBM', value: 4.76 },
    avgWeight: { unit: 'KG', weight: 250 },
    maxWeight: { unit: 'KG', weight: 6800 }
  },
  ULD_CONTAINER: {
    label: 'Unit Load Device - Container',
    value: 'ULD_CON',
    dimensions: { unit: 'CM', length: 318, width: 244, height: 160 },
    volume: { unit: 'CBM', value: 12.42 },
    avgWeight: { unit: 'KG', weight: 500 },
    maxWeight: { unit: 'KG', weight: 6800 }
  },
  SMALL_BOX: {
    label: 'Box / Carton - Small',
    value: 'SMB',
    dimensions: { unit: 'CM', length: 30, width: 30, height: 30 },
    volume: { unit: 'CBM', value: 0.027 },
    avgWeight: { unit: 'KG', weight: 5 },
    maxWeight: { unit: 'KG', weight: 15 }
  },
  MEDIUM_BOX: {
    label: 'Box / Carton - Medium',
    value: 'MDB',
    dimensions: { unit: 'CM', length: 60, width: 40, height: 40 },
    volume: { unit: 'CBM', value: 0.096 },
    avgWeight: { unit: 'KG', weight: 10 },
    maxWeight: { unit: 'KG', weight: 25 }
  },
  LARGE_BOX: {
    label: 'Box / Carton - Large',
    value: 'LGB',
    dimensions: { unit: 'CM', length: 80, width: 60, height: 60 },
    volume: { unit: 'CBM', value: 0.288 },
    avgWeight: { unit: 'KG', weight: 20 },
    maxWeight: { unit: 'KG', weight: 50 }
  },
  LUGGAGE: {
    label: 'Luggage',
    value: 'LUGG',
    dimensions: { unit: 'CM', length: 81, width: 51, height: 36 },
    volume: { unit: 'CBM', value: 0.288 },
    avgWeight: { unit: 'KG', weight: 23 },
    maxWeight: { unit: 'KG', weight: 32 }
  },
  CUSTOM_CRATE: {
    label: 'Custom Crate',
    value: 'CRATE',
    dimensions: { unit: 'CM', length: 120, width: 100, height: 100 },
    volume: { unit: 'CBM', value: 1.2 },
    avgWeight: { unit: 'KG', weight: 50 },
    maxWeight: { unit: 'KG', weight: 500 }
  },
  INSULATED_BOX: {
    label: 'Insulated Box',
    value: 'INS_BOX',
    dimensions: { unit: 'CM', length: 40, width: 30, height: 30 },
    volume: { unit: 'CBM', value: 0.036 },
    avgWeight: { unit: 'KG', weight: 8 },
    maxWeight: { unit: 'KG', weight: 20 }
  },
  WOODEN_CRATE: {
    label: 'Wooden Crate',
    value: 'WOOD_CRATE',
    dimensions: { unit: 'CM', length: 100, width: 80, height: 80 },
    volume: { unit: 'CBM', value: 0.64 },
    avgWeight: { unit: 'KG', weight: 30 },
    maxWeight: { unit: 'KG', weight: 500 }
  },
  WOODEN_PALLET: {
    label: 'Wooden Pallet',
    value: 'WOOD_PAL',
    dimensions: { unit: 'CM', length: 120, width: 80, height: 15 },
    volume: { unit: 'CBM', value: 0.144 },
    avgWeight: { unit: 'KG', weight: 15 },
    maxWeight: { unit: 'KG', weight: 1000 }
  },
  SKID: {
    label: 'Skid',
    value: 'SKID',
    dimensions: { unit: 'CM', length: 120, width: 80, height: 15 },
    volume: { unit: 'CBM', value: 0.144 },
    avgWeight: { unit: 'KG', weight: 15 },
    maxWeight: { unit: 'KG', weight: 1000 }
  },
  PLASTIC_DRUM: {
    label: 'Plastic Drum',
    value: 'PL_DRUM',
    dimensions: { unit: 'CM', length: 60, width: 60, height: 90 },
    volume: { unit: 'CBM', value: 0.324 },
    avgWeight: { unit: 'KG', weight: 20 },
    maxWeight: { unit: 'KG', weight: 220 }
  },
  STEEL_DRUM: {
    label: 'Steel Drum',
    value: 'ST_DRUM',
    dimensions: { unit: 'CM', length: 58, width: 58, height: 88 },
    volume: { unit: 'CBM', value: 0.296 },
    avgWeight: { unit: 'KG', weight: 25 },
    maxWeight: { unit: 'KG', weight: 250 }
  },
  FIBER_DRUM: {
    label: 'Fiber Drum',
    value: 'FIB_DRUM',
    dimensions: { unit: 'CM', length: 56, width: 56, height: 85 },
    volume: { unit: 'CBM', value: 0.267 },
    avgWeight: { unit: 'KG', weight: 15 },
    maxWeight: { unit: 'KG', weight: 200 }
  },
  PLASTIC_TOTE: {
    label: 'Plastic Tote / Bin',
    value: 'PL_TOTE',
    dimensions: { unit: 'CM', length: 60, width: 40, height: 30 },
    volume: { unit: 'CBM', value: 0.072 },
    avgWeight: { unit: 'KG', weight: 5 },
    maxWeight: { unit: 'KG', weight: 25 }
  },
  CARGO_NET: {
    label: 'Cargo Net',
    value: 'NET',
    dimensions: { unit: 'CM', length: 200, width: 200, height: 1 },
    volume: { unit: 'CBM', value: 0.004 },
    avgWeight: { unit: 'KG', weight: 2 },
    maxWeight: { unit: 'KG', weight: 100 }
  },
  CARGO_STRAP: {
    label: 'Cargo Strap',
    value: 'STRAP',
    dimensions: { unit: 'CM', length: 500, width: 5, height: 1 },
    volume: { unit: 'CBM', value: 0.00125 },
    avgWeight: { unit: 'KG', weight: 0.5 },
    maxWeight: { unit: 'KG', weight: 100 }
  },
  SHRINK_WRAP: {
    label: 'Shrink Wrap',
    value: 'SHR_WRAP',
    dimensions: { unit: 'CM', length: 100, width: 100, height: 0.5 },
    volume: { unit: 'CBM', value: 0.005 },
    avgWeight: { unit: 'KG', weight: 0.2 },
    maxWeight: { unit: 'KG', weight: 5 }
  },
  STRETCH_WRAP: {
    label: 'Stretch Wrap',
    value: 'STR_WRAP',
    dimensions: { unit: 'CM', length: 100, width: 100, height: 0.5 },
    volume: { unit: 'CBM', value: 0.005 },
    avgWeight: { unit: 'KG', weight: 0.2 },
    maxWeight: { unit: 'KG', weight: 5 }
  },
  BUBBLE_WRAP: {
    label: 'Bubble Wrap',
    value: 'BUB_WRAP',
    dimensions: { unit: 'CM', length: 100, width: 100, height: 1 },
    volume: { unit: 'CBM', value: 0.01 },
    avgWeight: { unit: 'KG', weight: 0.5 },
    maxWeight: { unit: 'KG', weight: 5 }
  },
  FOAM_PACKAGING: {
    label: 'Foam Packaging',
    value: 'FOAM_PKG',
    dimensions: { unit: 'CM', length: 100, width: 100, height: 5 },
    volume: { unit: 'CBM', value: 0.05 },
    avgWeight: { unit: 'KG', weight: 1 },
    maxWeight: { unit: 'KG', weight: 10 }
  },
  THERMAL_BLANKET: {
    label: 'Thermal Blanket',
    value: 'THERM_BLK',
    dimensions: { unit: 'CM', length: 100, width: 100, height: 0.5 },
    volume: { unit: 'CBM', value: 0.005 },
    avgWeight: { unit: 'KG', weight: 0.5 },
    maxWeight: { unit: 'KG', weight: 5 }
  },
  THERMAL_CONTAINER: {
    label: 'Thermal Container',
    value: 'THERM_CONT',
    dimensions: { unit: 'CM', length: 120, width: 100, height: 100 },
    volume: { unit: 'CBM', value: 1.2 },
    avgWeight: { unit: 'KG', weight: 30 },
    maxWeight: { unit: 'KG', weight: 500 }
  },
  GEL_PACK: {
    label: 'Gel Pack',
    value: 'GEL',
    dimensions: { unit: 'CM', length: 20, width: 15, height: 5 },
    volume: { unit: 'CBM', value: 0.0015 },
    avgWeight: { unit: 'KG', weight: 0.2 },
    maxWeight: { unit: 'KG', weight: 1 }
  },
  DRY_ICE: {
    label: 'Dry Ice',
    value: 'DRY_ICE',
    dimensions: { unit: 'CM', length: 25, width: 25, height: 25 },
    volume: { unit: 'CBM', value: 0.0156 },
    avgWeight: { unit: 'KG', weight: 5 },
    maxWeight: { unit: 'KG', weight: 20 }
  },
  UN_CERTIFIED_PACKAGING: {
    label: 'UN-Certified Packaging',
    value: 'UN_PKG',
    dimensions: { unit: 'CM', length: 60, width: 40, height: 40 },
    volume: { unit: 'CBM', value: 0.096 },
    avgWeight: { unit: 'KG', weight: 10 },
    maxWeight: { unit: 'KG', weight: 50 }
  },
  OVERPACK: {
    label: 'Overpack',
    value: 'OVERPACK',
    dimensions: { unit: 'CM', length: 120, width: 80, height: 80 },
    volume: { unit: 'CBM', value: 0.768 },
    avgWeight: { unit: 'KG', weight: 25 },
    maxWeight: { unit: 'KG', weight: 500 }
  },
  SPILL_PROOF_DRUM: {
    label: 'Spill-Proof Drum',
    value: 'SP_DRUM',
    dimensions: { unit: 'CM', length: 60, width: 60, height: 90 },
    volume: { unit: 'CBM', value: 0.324 },
    avgWeight: { unit: 'KG', weight: 25 },
    maxWeight: { unit: 'KG', weight: 250 }
  },
  POLY_BAG: {
    label: 'Poly Bag',
    value: 'POLY_BAG',
    dimensions: { unit: 'CM', length: 40, width: 30, height: 10 },
    volume: { unit: 'CBM', value: 0.012 },
    avgWeight: { unit: 'KG', weight: 1 },
    maxWeight: { unit: 'KG', weight: 10 }
  },
  SACK: {
    label: 'Sack',
    value: 'SACK',
    dimensions: { unit: 'CM', length: 50, width: 30, height: 20 },
    volume: { unit: 'CBM', value: 0.03 },
    avgWeight: { unit: 'KG', weight: 2 },
    maxWeight: { unit: 'KG', weight: 25 }
  },
  FOAM_CUSHIONED_BOX: {
    label: 'Foam-Cushioned Box',
    value: 'FOAM_BOX',
    dimensions: { unit: 'CM', length: 60, width: 40, height: 30 },
    volume: { unit: 'CBM', value: 0.072 },
    avgWeight: { unit: 'KG', weight: 8 },
    maxWeight: { unit: 'KG', weight: 50 }
  },
  DOUBLE_BOX: {
    label: 'Double-Box',
    value: 'DBL_BOX',
    dimensions: { unit: 'CM', length: 60, width: 40, height: 40 },
    volume: { unit: 'CBM', value: 0.096 },
    avgWeight: { unit: 'KG', weight: 12 },
    maxWeight: { unit: 'KG', weight: 50 }
  },
  ROLL_CAGE: {
    label: 'Roll Cage',
    value: 'ROLL_CAGE',
    dimensions: { unit: 'CM', length: 120, width: 80, height: 180 },
    volume: { unit: 'CBM', value: 1.728 },
    avgWeight: { unit: 'KG', weight: 40 },
    maxWeight: { unit: 'KG', weight: 500 }
  },
  DUNNAGE_BAG: {
    label: 'Dunnage Bag',
    value: 'DUN_BAG',
    dimensions: { unit: 'CM', length: 100, width: 80, height: 10 },
    volume: { unit: 'CBM', value: 0.08 },
    avgWeight: { unit: 'KG', weight: 0.5 },
    maxWeight: { unit: 'KG', weight: 5 }
  },
  PACKING_PEANUTS: {
    label: 'Packing Peanuts',
    value: 'PKG_PEANUTS',
    dimensions: { unit: 'CM', length: 50, width: 50, height: 50 },
    volume: { unit: 'CBM', value: 0.125 },
    avgWeight: { unit: 'KG', weight: 1 },
    maxWeight: { unit: 'KG', weight: 5 }
  },
  PADDED_MAILER: {
    label: 'Padded Mailer',
    value: 'PAD_MAILER',
    dimensions: { unit: 'CM', length: 30, width: 20, height: 2 },
    volume: { unit: 'CBM', value: 0.0012 },
    avgWeight: { unit: 'KG', weight: 0.1 },
    maxWeight: { unit: 'KG', weight: 1 }
  },
  DOCUMENT_ENVELOPE: {
    label: 'Document Envelope',
    value: 'DOC_ENV',
    dimensions: { unit: 'CM', length: 30, width: 20, height: 0.5 },
    volume: { unit: 'CBM', value: 0.0003 },
    avgWeight: { unit: 'KG', weight: 0.05 },
    maxWeight: { unit: 'KG', weight: 0.5 }
  }
};

export const SEA_CARGO_PACKAGE_TYPES = {
  BARREL: {
    label: 'Standard Barrel',
    value: 'BARREL',
    dimensions: { unit: 'CM', diameter: 58, height: 88 },
    volume: { unit: 'CBM', value: 0.296 },
    avgWeight: { unit: 'KG', weight: 25 },
    maxWeight: { unit: 'KG', weight: 250 }
  },
  PLASTIC_DRUM: {
    label: 'Plastic Drum',
    value: 'PL_DRUM',
    dimensions: { unit: 'CM', diameter: 60, height: 90 },
    volume: { unit: 'CBM', value: 0.324 },
    avgWeight: { unit: 'KG', weight: 20 },
    maxWeight: { unit: 'KG', weight: 220 }
  },
  STEEL_DRUM: {
    label: 'Steel Drum',
    value: 'ST_DRUM',
    dimensions: { unit: 'CM', diameter: 58, height: 88 },
    volume: { unit: 'CBM', value: 0.296 },
    avgWeight: { unit: 'KG', weight: 25 },
    maxWeight: { unit: 'KG', weight: 250 }
  },
  WOODEN_CRATE: {
    label: 'Wooden Crate',
    value: 'WOOD_CRATE',
    dimensions: { unit: 'CM', length: 120, width: 100, height: 100 },
    volume: { unit: 'CBM', value: 1.2 },
    avgWeight: { unit: 'KG', weight: 50 },
    maxWeight: { unit: 'KG', weight: 500 }
  },
  WOODEN_PALLET: {
    label: 'Wooden Pallet',
    value: 'WOOD_PAL',
    dimensions: { unit: 'CM', length: 120, width: 80, height: 15 },
    volume: { unit: 'CBM', value: 0.144 },
    avgWeight: { unit: 'KG', weight: 20 },
    maxWeight: { unit: 'KG', weight: 1000 }
  },
  SKID: {
    label: 'Skid',
    value: 'SKID',
    dimensions: { unit: 'CM', length: 120, width: 80, height: 15 },
    volume: { unit: 'CBM', value: 0.144 },
    avgWeight: { unit: 'KG', weight: 20 },
    maxWeight: { unit: 'KG', weight: 1000 }
  },
  CARTON_BOX: {
    label: 'Carton Box',
    value: 'CRT_BOX',
    dimensions: { unit: 'CM', length: 60, width: 40, height: 40 },
    volume: { unit: 'CBM', value: 0.096 },
    avgWeight: { unit: 'KG', weight: 10 },
    maxWeight: { unit: 'KG', weight: 30 }
  },
  BULK_BAG: {
    label: 'Bulk Bag (Jumbo Bag)',
    value: 'BULK_BAG',
    dimensions: { unit: 'CM', length: 100, width: 100, height: 120 },
    volume: { unit: 'CBM', value: 1.2 },
    avgWeight: { unit: 'KG', weight: 50 },
    maxWeight: { unit: 'KG', weight: 1000 }
  },
  BUNDLE: {
    label: 'Bundle',
    value: 'BUNDLE',
    dimensions: { unit: 'CM', length: 100, width: 50, height: 50 },
    volume: { unit: 'CBM', value: 0.25 },
    avgWeight: { unit: 'KG', weight: 20 },
    maxWeight: { unit: 'KG', weight: 300 }
  },
  BALE: {
    label: 'Bale',
    value: 'BALE',
    dimensions: { unit: 'CM', length: 120, width: 80, height: 80 },
    volume: { unit: 'CBM', value: 0.768 },
    avgWeight: { unit: 'KG', weight: 50 },
    maxWeight: { unit: 'KG', weight: 500 }
  },
  SACK: {
    label: 'Sack',
    value: 'SACK',
    dimensions: { unit: 'CM', length: 50, width: 30, height: 20 },
    volume: { unit: 'CBM', value: 0.03 },
    avgWeight: { unit: 'KG', weight: 2 },
    maxWeight: { unit: 'KG', weight: 25 }
  },
  PLASTIC_TOTE: {
    label: 'Plastic Tote / Bin',
    value: 'PL_TOTE',
    dimensions: { unit: 'CM', length: 60, width: 40, height: 30 },
    volume: { unit: 'CBM', value: 0.072 },
    avgWeight: { unit: 'KG', weight: 5 },
    maxWeight: { unit: 'KG', weight: 25 }
  },
  FOAM_CUSHIONED_BOX: {
    label: 'Foam-Cushioned Box',
    value: 'FOAM_BOX',
    dimensions: { unit: 'CM', length: 60, width: 40, height: 30 },
    volume: { unit: 'CBM', value: 0.072 },
    avgWeight: { unit: 'KG', weight: 8 },
    maxWeight: { unit: 'KG', weight: 50 }
  },
  SPILL_PROOF_DRUM: {
    label: 'Spill-Proof Drum',
    value: 'SP_DRUM',
    dimensions: { unit: 'CM', diameter: 60, height: 90 },
    volume: { unit: 'CBM', value: 0.324 },
    avgWeight: { unit: 'KG', weight: 25 },
    maxWeight: { unit: 'KG', weight: 250 }
  },
  OVERPACK: {
    label: 'Overpack',
    value: 'OVERPACK',
    dimensions: { unit: 'CM', length: 120, width: 80, height: 80 },
    volume: { unit: 'CBM', value: 0.768 },
    avgWeight: { unit: 'KG', weight: 25 },
    maxWeight: { unit: 'KG', weight: 500 }
  }
};
