import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

export default function DynamicTitle({ platform = 'console' }) {
  const location = useLocation();

  const platformTemplate = `- Kargoplex ${
    platform.charAt(0).toUpperCase() + platform.slice(1)
  }`;

  const platformTitles = useMemo(
    () => ({
      shipper: {
        '/dashboard': `Dashboard ${platformTemplate}`,
        '/register': `Register ${platformTemplate}`,
        '/forgot-password': `Forgot Password ${platformTemplate}`,
        '/login': `Login ${platformTemplate}`,
        '/quotes': `Quotes ${platformTemplate}`,
        '/collaborators': `Collaborators ${platformTemplate}`,
        '/attachment': `Attachments ${platformTemplate}`,
        '/shipments': `Shipments ${platformTemplate}`,
        '/subscriber-management': `Subscriber Management ${platformTemplate}`,
        '/team': `Team ${platformTemplate}`,
        '/mailbox': `Inbox ${platformTemplate}`,
        '/billing': `Billing ${platformTemplate}`,
        '/settings': `Settings ${platformTemplate}`
      }
    }),
    [platformTemplate]
  );

  const currentTitle =
    platformTitles[platform]?.[location.pathname] ||
    `Kargoplex - Simplify Your Logistics`;

  useEffect(() => {
    document.title = currentTitle;
  }, [currentTitle]);

  return (
    <Helmet>
      <title>{currentTitle}</title>
    </Helmet>
  );
}
