import {
    SHIPMENT_SEARCH_INFO,
    shippmentSearchInfo
} from '../actions';

const initialState = {
   details:[]
};

export default function shippmentBooking(state = initialState, action) {
    switch (action.type) {
        case SHIPMENT_SEARCH_INFO:
            return {
                details: action.payload
            };
        default:
            return state;
    }
}